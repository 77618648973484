.dt_cal {
  position: absolute;
  padding: 5px;
  width: 400px;
  color: #000000;
  background: #fff;
  border-radius: 4px;
  border: 1px #ccc solid;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
  z-index: 19999;
}

.dt_val {
  height: 35px;
  padding: 0px 5px;
  line-height: 35px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}
.dt_head {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-bottom: 1px #ccc solid;
}

.dt_content {
  width: 100%;
  font-size: 16px;
  text-align: center;
}
.dt_top_week {
  width: 50px;
  height: 25px;
  line-height: 25px;
  border-right: 1px #ff9900 solid;
  color: #888;
  background: #fff;
  font-size: 14px;
}
.dt_top {
  height: 25px;
  line-height: 25px;
  color: #888;
  background: #fff;
  font-size: 14px;
}
.dt_day {
  margin: 5px 0px;
  height: 30px;
  line-height: 30px;
  color: #222;
  background: #fff;
}
.dt_day:hover {
  color: #fff;
  background: #ff9900;
}
.dt_pday {
  color: #777;
}
.dt_today {
  color: #080;
  border: 1px solid #080;
}
.dt_seldat {
  color: #ff9900;
  border: 1px solid #ff9900;
}

.dt_month {
  margin: 5px 0px;
  height: 40px;
  line-height: 40px;
  color: #222;
  background: #fff;
}
.dt_month:hover {
  color: #fff;
  background: #ff9900;
}
.dt_year {
  margin: 5px 0px;
  height: 40px;
  line-height: 40px;
  color: #222;
  background: #fff;
  font-size: 18px;
}
.dt_year:hover {
  color: #fff;
  background: #ff9900;
}
.dt_foot {
  width: 100%;
  border-top: 1px #ccc solid;
  font-size: 14px;
}
.dt_week {
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-right: 1px #ff9900 solid;
  color: #888;
  background: #fff;
}

.txt_btn {
  width: 125px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  color: #222;
  background: #fff;
}
.txt_btn:hover {
  color: #fff;
  background: #ff9900;
}
.foot_btn {
  width: 125px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #222;
  background: #fff;
}
.foot_btn:hover {
  color: #ff9900;
}
.tpv {
  padding: 5px 0px;
}
.tpv-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(6, 1fr);
}
.tpv-grid .hh-body {
  padding: 0px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.tpv-grid .hh-body:hover {
  border: 1px solid #ff9900;
}

.dt_row {
  margin: 5px 0px;
  height: 30px;
  line-height: 30px;
  color: #222;
  background: #fff;
}
.dt_row:hover {
  color: #fff;
  background: #ff9900;
}
