.mnu-base {
    font-family: Georgia;
    line-height: normal;
    padding:10px 15px;
}
.mnu-row {
    background: #000;
    padding: 0px 0px;
}
.mnu_xtop {
  display: flex;
  flex-direction: row;
  height: 30px;
  padding: 0px 5px;
  background: #000;
  color: #fff;
  border-bottom: 1px solid #ff9900;
  font-size: 22px;
}
.mnu_foot {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding: 0px;
  background: #eee;
  border-top: 1px solid #ff9900;
  font-size: 16px;
}
.mnu_title {
  padding: 0px 5px;
  font-size: 22px;
}
.mnu_head {
  padding: 0px 5px;
  background: #fff;
  border-bottom: 1px solid #ff9900;
  font-size: 22px;
}
.mnu_grp {
  width: 100%;
  padding: 10px 0px;
  text-align: left;
  font-size: 18px;
  border-bottom: 1px solid #ff9900;
}
.mnu_grp:hover {
  background: #080;
  color: #fff;
}
.mnu_col {
  padding: 0px 5px;
  font-size: 22px;
}
.mnu_item {
  border-bottom: 1px solid #00cc00;
}
.mnu_item:hover {
  background: #eee;
}
.mnu_text_0 {
  color: #ff9900;
  font-size: 18px;
}
.mnu_text_1 {
  font-size: 14px;
}
.mnu_text_2 {
  font-size: 12px;
}

.category-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.category-container > .dish-container :last-child {
    margin-bottom: 5px;
}

.category-box {
    margin-top: 5px;
    margin-bottom: 5px;
}
.category-title {
    font-size: 32px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0px;
    font-family: Chalkboard;
    color: #F36E21;
}

.dish-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}

.dish-title {
    font-family: Chalkboard;
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ffffff;
}

.dish-description {
    font-family: Chalkboard;
     font-size: 18px;
    color: #ffffff;
}

.normal-text {
    font-size: 16px;
    margin-top: 12px;
    color: #ffffff;
}

.dish-pricing {
    font-size: 18px;
    text-align:right;
    color: #ffffff;
}

.dish-comment {
    margin-top: 10px;
    font-size: 12px;
    color: #ffffff;
}

.flex_row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.flex_col {
  heigth: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.flex_ss   {
  justify-content: flex-start;
}
.flex_stretch   {
  align-content: stretch
}
