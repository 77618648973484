.rr_row {
  margin: 5px 0px;
  padding: 0px;
  height: 35px;
  line-height: 35px;
}
.rr_txt {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  overflow: hidden;
}
.rr_grp {
  width: 200px;
  height: 55px;
  margin: 2px 5px;
  padding: 0px;
  background: #fff;
  color: #222;
  border: 1px solid #222;
  border-radius: 2px;
  text-align: center;
}
.rr_grp:hover {
  background: #ccc;
  color: #222;
}
.rr_pos {
  margin: 0px 5px;
  padding: 0px;
  background: #080;
  color: #fff;
  border: 1px solid #ff9900;
  text-align: center;
}
.rr_pos:hover {
  background: #ff9900;
  color: #fff;
}
.rr_pre {
  width: auto;
}
.rr_post {
  width: auto;
}
.rr_avr {
  width: 15px;
  background: repeating-linear-gradient(
  45deg,
  #fff,
  #fff 10px,
  #c81919 10px,
  #c81919 20px
  );
  border: 1px solid #800;
  border-radius: 2px;
  color: #fff;
}
.rr_ank {
  width: 15px;
  background: repeating-linear-gradient(
  45deg,
  #fff,
  #fff 10px,
  #080 10px,
  #080 20px
  );
  border: 1px solid #080;
  border-radius: 2px;
  color: #fff;
}
.rr_clean {
  width: 15px;
  background: #f1e71c;
  color: #fff;
}
.rr_close {
  background: #000;
  color: #fff;
}
.rr_free {
  background: #fff;
  color: #222;
}
.rr_free:hover {
  background: #ccc;
  color: #222;
}
.rr_inh {
  background: #800;
  color: #fff;
}
.rr_inh:hover {
  background: #ccc;
  color: #222;
}
