.svk-s0 {
  background-color: rgba(255, 255, 255, 0);
  color: #000;
  margin: 0px;
  padding: 50px 0px;
}
.svk-s1 {
  background-color: rgb(255, 255, 255);
  color: #000000;
  margin: 0px 5px;
  padding: 5px 15px;
}

.svk-s2 {
  background-color: rgb(255, 255, 255);
  color: #000;
  margin: 0px;
  padding: 0px;
}
.svk-s11 {
  background-color: #427ac1;
  color: #fff;
  margin: 0px;
  padding: 50px 0px;
}
.svk-blue {
  background: linear-gradient(90deg, #427ac1 30%, #fff 30%);
  color: #fff;
}

.svk-bg00 {
/*  background-image: url("../img/kassabg.jpg");*/
}
.img_fill {
  object-fit: fill;
}
.img_cover {
  object-fit: cover;
}
.img_contain {
  object-fit: contain;
}
.hover:hover {
  border: 1px solid #800;
}
.responsive {
  width: 100%;
  height: auto;
}
.svk-txt {
  padding: 0px 3px;
  font-size: 18px;
  color: #4285F4;
  line-height: 50px;
}
.svk-logo {
  padding: 0px 3px;
  height: 50px;
  line-height: 50px;
}
.svk-kub {
  padding: 5px 3px;
  height: 50px;
  line-height: 50px;
}
.mnu_dd {
  padding: 0px 3px;
  background: #fff;
}
.item-sys {
  font-size: 10px;
  text-align: left;
}
.item-fkn {
  font-size: 18px;
  text-align: left;
}
.cdi-table {
  flex: 1;
  margin: 0px;
  padding: 0px 5px;
  background: #fff;
  color: #222;
  font-size: 16px;
}
.cdi-col {
  margin: 0px;
  padding: 0px;
}
.cdi-head {
  margin: 0px;
  padding: 5px 5px;
  background: #fff;
  color: #000;
  border-bottom: 1px solid #ff9900;
}
.cdi-row {
  margin: 0px;
  padding: 5px 5px;
  background: #fff;
  color: #222;
  border-bottom: 1px solid #ddd;
}
.cdi-row:hover {
  background: #ff9900;
  color: #fff;
}
.text-valr {
  text-align: center;
  padding-right: 0px;
}
.wnd_icon {
  color: #ccc;
  width: 50px;
  height: 35px;
  text-align: center;
  padding: 5px 5px;
  cursor: pointer;
}
.wnd_icon:hover {
  color: #ff9900;
}
.wnd_link {
  margin: 0px;
  border: 0px;
  padding: 0px 5px;
  color: #008800;
  text-align: center;
  font-size: 11px;
  cursor: pointer;
}
.wnd_link:hover {
  color: #ff9900;
}

.grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto auto;
}
.grid > * {
  padding: 5px;
  min-height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.grid > *:hover {
  border: 1px solid #ff9900;
}
.bq-row {
  color: #3074bc;
  cursor: pointer;
}
.bq-row:hover {
  color: #ff9900;
}

.bv {
  padding: 0px 10px;
}
.bv-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
}
.bv-grid .btn-body {
  padding: 0px;
  height: 65px;
  line-height: 65px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.bv-grid .btn-body:hover {
  border: 1px solid #ff9900;
}
.btn-bottom {
  position: absolute;
  height: 12px;
  padding: 0px 3px;
  width: 100%;
  bottom: 1px;
  font-size: 10px;
}
.sztxt {
  width: 250px;
  font-size: 16px;
  text-align: left;
  padding-left: 5px;
  border: 1px solid #dedede;
  border-radius: 3px;
}


.bqv {
   display: flex;
   align-items: start;
     /*justify-content: start;
   /* You can set flex-wrap and
      flex-direction individually */
   flex-direction: column;
   flex-wrap: wrap;
   /* Or do it all in one line
     with flex flow */
   flex-flow: column wrap;
   /* tweak where items line
      up on the row
      valid values are: flex-start,
      flex-end, space-between,
      space-around, stretch */
   align-content: flex-start;
}
.bqv .bong {
  padding: 0px;
  width: 200px;
  min-height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.bqv .bong:hover {
  background: #ddd;
  border: 1px solid #ff9900;
}

.bqvx {
  padding: 0px 10px;
}
.bqv-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(200, 1fr);
}
.bqv-grid .bong {
  padding: 0px;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.bqv-grid .bong:hover {
  border: 1px solid #ff9900;
}
.bqv-grid .bong-body {
  padding: 0px;
  color: #080;
}

.mnu-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}
.mnu-grid-3 {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.cursor-point {
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
