.btn_drag {
  background: #800;
  color: #fff;
  margin: 5px 0px;
  padding: 0px;
  width: 125px;
  height: 65px;
  line-height: 30px;
  border: 1px solid #ff9900;
  text-align: center;
}
.btn_row {
  margin: 5px 0px;
  padding: 0px;
  height: 65px;
  line-height: 65px;
}
.btn_grp {
  margin: 0px 5px;
  padding: 0px;
  background: #008;
  color: #fff;
  border: 1px solid #ff9900;
  text-align: center;
  overflow:hidden;
  cursor: pointer;
}
.btn_grp:hover {
  background: #ff9900;
  color: #fff;
}
.btn_pos {
  margin: 0px;
  padding: 0px;
  background: #080;
  color: #fff;
  border: 1px solid #ff9900;
  text-align: center;
  overflow:hidden;
}
.btn_pos:hover {
  background: #ff9900;
  color: #fff;
}
.xbtn_free {
  margin: 0px;
  padding: 0px;
  background: repeating-linear-gradient(
    -55deg,
    #222,
    #222 10px,
    #333 10px,
    #333 20px
  );
  color: #fff;
  border: 1px solid #ff9900;
  text-align: center;
  overflow:hidden;
}
.btn_free {
  margin: 0px;
  padding: 0px;
  color: #ccc;
  border: 1px solid #ff9900;
  text-align: center;
  overflow:hidden;
}
.btn_sel {
  margin: 2px;
  padding: 0px;
  min-width: 200px;
  min-height: 35px;
  line-height: 35px;
  color: #fff;
  background: #6c99d3;
  border: 1px solid #6c99d3;
  text-align: center;
  overflow:hidden;
  border-radius: 5px;
}
.btn_sel:hover {
  background: #ff9900;
  color: #fff;
}
