.flex-wrapper {
     display: flex;
    flex-flow: row nowrap;
  }
  
  .single-chart {
    width: 100%;
    justify-content: space-around ;
  }
  
  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  .circular-chart.orange .circle {
    stroke: #ff9f00;
  }
  
  .circular-chart.green .circle {
    stroke: #4CC790;
  }
  
  .circular-chart.blue .circle {
    stroke: #3c9ee5;
  }
  
  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }