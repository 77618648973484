
.cdi_list {
  padding: 0px 5px;
}
.cdi_list_row {
  padding: 0px 5px;
  border: 1px #dedede solid;
}
.cdi_list_row:hover {
  color: #ff9900;
  background: #eee;
  cursor: pointer;
}
