.edit_sel {
  position: absolute;
  padding: 5px;
  min-width: 300px;
  color: #000000;
  background: #fff;
  border-radius: 4px;
  border: 1px #ccc solid;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
  z-index: 19999;
}

.sz_val {
  padding: 0px 3px;
  min-width: 200px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  text-align: left;
  border: 1px #ccc solid;
}
.sz_top {
}

.edit_val {
  padding: 0px;
  cursor: pointer;
  text-align: left;
}
.edit_vx {
  padding: 0px;
  cursor: pointer;
  text-align: left;
}
.edit_txt {
  padding: 0px 5px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
}
.edit_top {
  width: 100%;
  height: 100%;
}
.edit_text {
  padding: 0px 5px;
  width: 100%;
  height: 100%;
}
.edit_top_val {
  height: 50px;
  padding: 0px 5px;
  line-height: 50px;
  cursor: hand;
  font-size: 16px;
  text-align: center;
}
.edit_head {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-bottom: 1px #ff9900 solid;
}
.edit_foot {
  width: 100%;
  border-top: 0px #ccc solid;
  font-size: 14px;
}

.edit_content {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
}
