/* PMS RV */
.rv_cdi_01 {
  padding: 0px 5px;
  font-size: 16px;
  background: #fff;
  color: #080;
  text-align: center;
  border-right: 1px dashed #ccc;
	cursor: pointer;
}
.rv_cdi_01:hover {
	color: #fff;
	background-color: #080;
	border-radius: 4px;
}
.rv_cdi_02 {
  padding: 0px 10px;
  font-size: 16px;
  background: #fff;
  color: #008;
  text-align: center;
  border-right: 1px dashed #ccc;
	cursor: pointer;
}
.rv_cdi_02:hover {
	color: #fff;
	background-color: #008;
	border-radius: 4px;
}
.table tbody tr {
	cursor: pointer;
}
.table tbody tr:hover {
	color: #fff;
	background-color: #ff9700;
}
.bold {
	font-weight: 600;
}

/* PMS */
.table>:not(caption)>*>* {
	margin: 10px;
	padding: 5px;
	height: 15px;
	background-color: transparent;
	border-bottom:1px solid #ccc;
}
.tb_pres {
	width: 100%;
	margin: 10px 0px;
	padding: 5px;
	font-size: 12px;
	letter-spacing:1px;
}
.tb_pres thead {
	margin: 10px;
	padding: 5px;
	height: 15px;
	color: #fff;
	background-color: #345498;
	border-bottom:1px solid #ccc;
}
.tb_pres thead th {
	text-align: center;
	border-right:1px solid #fff;
	padding: 2px;
}
.tb_pres thead td {
	padding: 2px;
}
.tb_pres thead .header {
	text-align: left;
	border-right:1px solid #800;
	vertical-align: middle;
}
.tb_pres .highlite {
	color: #fff;
	background-color: #080;
}

.tb_pres .row_selected {
	color: #fff;
	background-color: #080;
}
.tb_pres tbody {
	color: #444;
	background-color: #fff;
}
.tb_pres tr {
	margin: 10px;
	padding: 5px;
	height: 25px;
	border: none;
	border-bottom:1px solid #ccc;
}
.tb_pres tbody.nohover tr:hover {
	color: #ff9700;
}
.tb_pres tbody tr:hover {
	color: #fff;
	background-color: #ff9700;
}
.tb_pres tbody tr:hover .dtactive{
	color: #fff;
	background-color: #0a0;
}
.tb_pres tbody tr:hover .weekend{
	color: #fff;
	background-color: #ff9700;
}
.tb_pres tbody tr:hover .weekend2{
	color: #fff;
	background-color: #ff9700;
}
.tb_pres td {
	border: none;
	border-right:1px solid #ccc;
	vertical-align: middle;
	text-align: center;
	padding: 2px;
	font-size: 14px;
}
.tb_pres tbody td {
	padding: 2px;
}
.tb_pres .header {
	text-align: left;
	border-right:1px solid #800;
}
.tb_pres .headcol {
	text-align: center;
	border-right:1px solid #800;
}
.tb_pres .seprow {
	border-top:1px solid #800;
}
.tb_pres .tb_rv {
	height: 50px;
	overflow-y: auto;
}
.tb_pres .weekend {
	background-color: #eee;
}
.tb_pres .weekend2 {
	background-color: #ddd;
}
.tb_pres .dtactive {
	background-color: #0a0;
}
.tb_pres .totrad {
	background-color: #8b8;
}
.tb_pres .totrad td {
	font-weight: bold;
}
.table-striped tbody tr.totrad:nth-of-type(odd) {
	background-color: #8b8;
}
.tb_pres .overbook {
	background-color: #b55;
	color: #fff;
}

.rf_drag {
  background: #fff;
  color: #222;
  margin: 5px 0px;
  padding: 0px;
  width: 250px;
  height: 65px;
  line-height: 30px;
  border: 1px solid #ff9900;
  text-align: center;
}
