
.flex {
  flex: 1;
  -webkit-flex: 1;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-fullcol {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-start {
  justify-content: start;
  text-align: left;
}
.flex-center {
  justify-content: center;
  text-align: center;
}
.flex-sb {
  justify-content: space-between;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-vc {
  align-items: center;
}
.flex-vcenter {
  justify-content: center;
  align-items: center;
}
.flex-cc {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.flex-end {
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}
.self-end {
  align-self: flex-end;
}

.flex-content {
  overflow: auto;
  padding: 10px;
}
.f10 {
  flex: 0.10;
  -webkit-flex: 0.10;
}
.f20 {
  flex: 0.20;
  -webkit-flex: 0.20;
}

.f25 {
  flex: 0.25;
  -webkit-flex: 0.25;
}
.f30 {
  flex: 0.30;
  -webkit-flex: 0.30;
}
.f40 {
  flex: 0.40;
  -webkit-flex: 0.40;
}
.f45 {
  flex: 0.45;
  -webkit-flex: 0.45;
}
.f50 {
  flex: 0.50;
  -webkit-flex: 0.50;
}
.f60 {
  flex: 0.60;
  -webkit-flex: 0.60;
}
.f70 {
  flex: 0.70;
  -webkit-flex: 0.70;
}
.f75 {
  flex: 0.75;
  -webkit-flex: 0.75;
}
.f80 {
  flex: 0.80;
  -webkit-flex: 0.80;
}

.ff-col {
  display: flex;
  flex-direction: column;
  padding: 2px;
}
.ff-row {
  display: flex;
  flex-direction: row;
  padding: 2px;
  box-sizing: border-box;
}
.ff-label-row {
  padding: 0px;
  min-width: 125px;
}
.ff-box {
  margin: 5px;
  padding: 10px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
}
