.fm_ctrl {
  padding: 0px;
  margin: 5px 0px;
  cursor: hand;
  text-align: left;
}

.no_label {
  padding: 0px 5px;
}
.fm_label {
  position: absolute;
  padding: 0px 5px;
  text-align: left;
}
.ff_ctrl {
  padding: 0px;
  margin: 0px;
  cursor: hand;
  text-align: left;
}
.ff_label {
  margin: 0px;
  padding: 0px 5px;
  text-align: left;
  height: 12px;
  line-height: 12px;
  font-size: 12px;
}

.fm_input {
  padding: 0px;
  text-align: left;
}
.xfm_input:focus {
  outline: none;
}
.xform-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}