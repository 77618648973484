/* Carousel */

.carousel{
    background: #2f4357;
}
.carousel .item-image {
    height: 800px;
}
.carousel .item-image img{
    margin: 0 auto;
}

.carousel-caption {
    background-color: rgba(217, 223, 234, 0.3);
    background-color: rgba(64, 64, 64, 0.5);
    border-radius: 4px;
    padding-left: 10px;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    position: fixed;
}

.carousel-caption h1 {
    line-height: 50px;
}
.carousel-caption h3 {
    font-size: 62px;
}
.carousel-caption p {
    font-size: 42px;
}

.carousel-caption .btn {
    margin-top: 30px;
}

.carousel-inner {
    overflow: hidden;
}

.btn-slider {
    border-radius: 50px;
    padding: 15px 25px;
    border: 1px solid #fff;
    color: #fff;
    letter-spacing: 1.5px;
    font-size: 16px;
    font-weight: bold;
}


/* Ken Burns Effect */

.item-image {
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 16s;
    transform: scale(1.2);
}

.item-image:nth-child(1) {
    animation-name: kenburns-1;
    z-index: 3;
}
.item-image:nth-child(2) {
    animation-name: kenburns-2;
    z-index: 2;
}
.item-image:nth-child(3) {
    animation-name: kenburns-3;
    z-index: 1;
}

@keyframes kenburns-1 {
    0% {
    	opacity: 0;
    }
    5% {
    	opacity: 1;
    }
    95% {
    	transform: scale3d(1.2, 1.2, 1.2) translate3d(69px, -2px, 0px);
    	animation-timing-function: ease-in;
    	opacity: 1;
    }
    100% {
    	transform: scale3d(1.7, 1.7, 1.7) translate3d(-11px, -12px, 0px);
    	opacity: 0;
    }
}

@keyframes kenburns-2 {
    0% {
    	opacity: 0;
    }
    5% {
    	opacity: 1;
    }
    95% {
    	transform: scale3d(1.2, 1.2, 1.2) translate3d(69px, -2px, 0px);
    	animation-timing-function: ease-in;
    	opacity: 1;
    }
    100% {
    	transform: scale3d(1.7, 1.7, 1.7) translate3d(-11px, -12px, 0px);
    	opacity: 0;
    }
}

@keyframes kenburns-3 {
    0% {
    	opacity: 0;
    }
    5% {
    	opacity: 1;
    }
    95% {
    	transform: scale3d(1.2, 1.2, 1.2) translate3d(69px, -2px, 0px);
    	animation-timing-function: ease-in;
    	opacity: 1;
    }
    100% {
    	transform: scale3d(1.7, 1.7, 1.7) translate3d(-11px, -12px, 0px);
    	opacity: 0;
    }
}