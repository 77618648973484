.svepos-logo {
    width: 200px;
    z-index: 1001;
}
.svepos-top-logo {
    height: 45px;
    z-index: 1001;
}
.svepos-top {
    background-color: rgb(59, 114, 215);
    padding: 0px;
}
@keyframes fmvout {
    from {
        opacity: 0;
        height: 50px;
    }
    to {
        opacity: 1;
        height: 50px;
    }
}
.fmv0 {
    height: 50px;
    opacity: 1;
    transition: height .5s, opacity .5s;
}
.fmvz {
    height: 50px;
    opacity: 1;
    animation: 0.5s ease-out 0s 1 fmvout;
}
.fmv1 {
    height: 0px;
    opacity: 0;
}

.svp-head {
    margin: 0px auto;
    padding: 0px;
    max-width: 1024px;
}
.svp-body {
    margin: 0px auto;
    padding: 0px;
    max-width: 1024px;
}
.svp-foot {
    margin: 0px auto;
    padding: 0px;
    max-width: 1024px;
}
.svp-slide {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
}
.svp-s1 {
    background-color: #FCF6F5FF;
    color: #222;
}
.svp-s2 {
    background-color: rgb(59, 114, 215);
    color: #fff;
}
.svp-s3 {
    background-color: #080;
    color: #fff;
}
.svp-s4 {
    background-color: #800;
    color: #fff;
}
.svp-s5 {
    background-color: #008;
    color: #fff;
}
.svp-txt {
    font-size: 24px;
}
.svp-home-body {
    background-color: #fff;
    padding: 25px;
}
.svp-box {
    border: 1px solid #222;
    border-radius: 5px;
}

.svepos-foot {
    background-color: rgb(59, 114, 215);
    padding: 0px;
    min-height: 200px;
}
.svepos_bottom_logo {
    text-align: left;
}

.cdi-top {
    position: fixed;
    width: 100%;
    min-height: 35px;
    background-color: transparent;
    color: #ffffff;
    margin: 0px;
    padding: 0px;
    z-index: 9900;
    font-size: 18px;
}
.cdi-head {
    background-image: url("../../img/kassabg.jpg");
    background-color: #aaa;
    background-size: cover;
    background-position: center;
    background-blend-mode: exclusion;
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin: 0px;
    padding: 0px;
    padding-top: 35px;
    color: #ffffff;
}
.cdi-body {
    margin: 0px auto;
    padding: 50px 0px;
    max-width: 1024px;
}

.vert_split {
    border-left: 1px solid #ccc;
}
.txt-16 {
    font-size: 16px;
}
.txt-18 {
    font-size: 18px;
}
.txt-20 {
    font-size: 20px;
}
.txt-22 {
    font-size: 22px;
}
.txt-32 {
    font-size: 32px;
}
.txt-bold {
    font-weight: bold;
}
