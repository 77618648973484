
.rand-01 {
	background-image: linear-gradient(
			15deg,
			#B2A2CD 25%,
			#5F5370 25%,
			#5F5370 50%,
			#B2A2CD 50%,
			#B2A2CD 75%,
			#5F5370 75%,
			#5F5370 100%
	);
	background-size: 154.55px 41.41px;
}
.rand-02 {
	background-image: linear-gradient(
			45deg,
			#c28c3c 25%,
			#cccccc 25%,
			#cccccc 50%,
			#c28c3c 50%,
			#c28c3c 75%,
			#cccccc 75%,
			#cccccc 100%
	);
	background-size: 40.00px 40.00px;
}
