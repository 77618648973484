html, body {
  font-family: 'Poppins', 'Roboto', 'Garamond', sans-serif;
  font-size: 14px;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.web-app {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  -webkit-flex: 1;
}
.web-appx {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  -webkit-flex: 1;
}
.web-logo {
  float: right;
  z-index: 1090;
}
.web-top {
  /*background-color: #283061; #3f51b5 #4755AB*/
  /*background-color: rgb(13,71,161);*/
  /*background-color: #1565c0;*/
  background-color: #fff;
  color: #fff;
  display: flex;
  height: 50px;
  z-index: 1110;
}

.web-title {
  /*background-color: #283061;*/
  background-color: rgb(255, 255, 255);
  color: #000;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex: 0;
  -webkit-flex: 0;
  height: 50px;
  z-index: 1010;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}

.web-menu {
  background-color: #ccc;
  border-right: 0px solid #800;
  display: flex;
  width: 50px;
  z-index: 1010;
}
.web-side {
  flex: 0;
  -webkit-flex: 0;
  background-color: #ccc;
  border-right: 0px solid #800;
  display: flex;
  z-index: 999;
}

.web-top-btn {
  padding: 5px 5px;
}
.web-top-id {
  display: flex;
  padding: 2px 5px;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  z-index: 1010;
}
.web-top-id .item:hover {
  background-color: #ff9900;
  color: #fff;
}

.web-top-mnu {
  display: flex;
  padding: 0px 5px;
  font-size: 18px;
  font-weight: normal;
  z-index: 1010;
}
.web-top-mnu .item {
  border-right: 0px solid #800;
  padding: 0px 5px;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
}
.web-top-mnu .item:hover {
  background-color: #ff9900;
  color: #fff;
}
.web-top-mnu .selected {
  background-color: #080;
  color: #fff;
  transition: 1s;
}
.web-mnu {
  background-color: #fff;
  border-right: 1px solid #800;
  padding: 0px 5px;
  display: flex;
  z-index: 1010;
}

.web-mnu-tab {
  background-color: #fff;
  border-right: 1px solid #800;
  margin: 0px;
  padding: 0px 5px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px #ccc solid;
  display: flex;
  font-size: 16px;
  z-index: 1010;
}
.web-mnu-tab:hover {
  background-color: #ff9900;
  color: white;
}
.web-top a {
  text-decoration: none;
  color: white;
}
.web-top a:link {
  text-decoration: none;
}
.web_mnu_link {
  height:20px;
  line-height:20px;
  background-color: #fff;
  color: #1e6dcf;
  padding: 5px 5px;
  cursor:pointer;
}
.web_mnu_link:hover {
  background-color: #fff;
  color: #ff9900;
}
.web_mnu_head {
  height:20px;
  line-height:20px;
  background-color: #fff;
  color: #cd1c1c;
  padding: 5px 5px;
  text-align:center;
  cursor:pointer;
}
.web_mnu_head:hover {
  background-color: #fff;
  color: #ff9900;
}

/*
.web-top .dropdown-menu {
  position: absolute;
  display: none;
  z-index: 1010;
}
*/
.web-nav {
  /* background-color: #eff1f9; */
  width: 100%;
  flex: 0;
  -webkit-flex: 0;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}
.web-bread {
  /* background-color: #eff1f9; */
  margin-bottom: 0px;
  padding: 0px 5px;
  border-bottom: 1px solid #ddd;
}
.web-bread li.active {
  /* background-color: #eff1f9; */
  color: #3651bc;
}
.breadcrumb {
  width: 100%;
  margin: 5px 5px;
}
.breadcrumb-item {
  color: #525252;
  font-style: italic;
  cursor: pointer;
}
.breadcrumb-item:hover {
  /* background-color: #eff1f9; */
  color: #ff9900;
}

.web-body {
  /* background-color: #eff1f9; padding-top: 50px; */
  padding: 0px;

  background: #fff;
  width: 100%;
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.web-main {
  background-color: #fff;
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  overflow: auto;
  margin-top: 0px;
}

.web-left {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0px;
  min-width: 250px;
  border-right: 1px solid #ff9900;
  overflow: auto;
}

.web-right {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0px;
  min-width: 250px;
  border-left: 1px solid #ff9900;
  overflow: auto;
}

.web-footx {
  background-color: rgb(13,71,161);
  color: #ffffff;
  display: flex;
  height: 50px;
}
.web-foot {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ff9900;
  background-color: rgb(255,255,255);
  color: #555;
}
.web-foot-btn {
  margin: 0px;
  padding: 0px;
  width: 125px;
  height: 35px;
  line-height: 35px;
  border-right: 1px solid #ff9900;
  background-color: #E7EDF6;
  color: #222;
  text-align: center;
  cursor: pointer;
}
.web-foot-btn:hover {
  background-color: #4755AB;
  color: #fff;
}
.web-foot-icon {
  margin: 0px;
  padding: 2px 0px;
  width: 75px;
  height: 35px;
  line-height: 35px;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  text-align: center;
  cursor: pointer;
}
.web-foot-icon:hover {
  background-color: #4755AB;
  color: #fff;
}
.web-foot-icon:hover i {
  color: #fff;
}
.web-foot-cmd {
  margin: 0px;
  padding: 0px;
  width: 125px;
  height: 35px;
  line-height: 35px;
  border-right: 1px solid #d5d3d0;
  background-color: #9da3ac;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.web-foot-txt {
  padding: 5px 3px;
}
.web-foot-x {
  margin: 0px;
  padding: 0px 5px;
  font-size: 18px;
  line-height: 35px;
  border-right: 1px solid #ff9900;
  cursor: pointer;
}
.web-foot-mnu {
  margin: 0px;
  padding: 2px 0px;
  width: 60px;
  height: 35px;
  line-height: 35px;
  border-right: 1px solid #ff9900;
  text-align: center;
  cursor: pointer;
}
.web-foot-mnu:hover {
  background-color: #4755AB;
  color: #fff;
}
.web-foot-mnu:hover i {
  color: #fff;
}
.web-foot-rmnu {
  margin: 0px;
  padding: 2px 0px;
  width: 60px;
  height: 35px;
  line-height: 35px;
  border-left: 1px solid #ff9900;
  border-right: 1px solid #ff9900;
  text-align: center;
  cursor: pointer;
}
.web-foot-rmnu:hover {
  background-color: #4755AB;
  color: #fff;
}
.web-foot-rmnu:hover i {
  color: #fff;
}
.web-foot-info {
  margin: 0px;
  padding: 0px 2px;
  border-right: 1px solid #ff9900;
  text-align: center;
  cursor: pointer;
}
.web-foot-info:hover {
  background-color: #4755AB;
  color: #fff;
}

.web-foot-txt {
  margin: 0px;
  padding: 0px 2px;
  height: 35px;
  line-height: 15px;
  border-right: 0px solid #ff9900;
  text-align: left;
  cursor: pointer;
}
.web-foot-txt:hover {
  background-color: #4755AB;
  color: #fff;
}

.web-copyright {
  border-top: 1px solid #080;
  background-color: rgb(255,255,255);
  color: #000;
  display: flex;
  height: 50px;
  margin: 0px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: normal;
}

.web-row {
  display: flex;
  flex-direction: row;
}

.web-col {
  display: flex;
  flex-direction: column;
}

.web-mnu-bars {
  display: none;
}

.cb {
  justify-content: space-between;
}
.cc {
  justify-content: center;
}
.cx {
  justify-content: center;
  -webkit-align-items: center;
}

.spbw {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-justify-content: space-between;
  list-style: none;
}

.web-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.web-p00 {
  padding: 0px 5px;
}
.web-rows {
  flex-direction: column;
  line-height: 15px;
  padding: 0px;
}

.web-p01 {
  padding: 5px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.wnd-cr-01 {
  background-color: #9575CD;
  color: #fff;
}
.wnd-cr-02 {
  background-color: #7E57C2;
  color: #fff;
}
.wnd-cr-03 {
  background-color: #232F34;
  color: #fff;
}
.wnd-bg {
  background-color: #b1bfd8;
  background-image: linear-gradient(315deg, #b1bfd8 0%, #6782b4 74%);
  color: #fff;
}
.wnd-bg-01 {
  background-color: #36669d;
  background-image: linear-gradient(315deg, #36669d 0%, #6782b4 74%);
  color: #fff;
}
.wnd-bg-02 {
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #ececec 74%);
  color: #222;
}

.wnd-bb {
  margin: 5px;
  padding: 10px;
  line-height: 22px;
  background-color: #fff;
  color: #2571a5;
  text-align: center;
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.wnd-bb:hover {
  background-color: #2571a5;
  color: #fff;
}

.wnd-btn {
  margin: 5px;
  padding: 10px;
  width: 100%;
  height: 60px;
  line-height: 22px;
  background-color: #fff;
  color: #2571a5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.wnd-btn:hover {
  background-color: #2571a5;
  color: #fff;
}

.pms-btn {
  margin: 5px;
  padding: 5px;
  width: 100%;
  height: 50px;
  line-height: 20px;
  background-color: #fff;
  color: #2571a5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.pms-btn:hover {
  background-color: #2571a5;
  color: #fff;
}

.web-btn {
  margin: 5px;
  padding: 5px;
  height: 90px;
  line-height: 20px;
  background-color: #fff;
  color: #222;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 2px #eee;
}

.web-btn:hover {
  background-color: #ccc;
  color: #222;
}

.web-mnu-btn {
  margin: 5px;
  padding: 5px 15px;
  height: 90px;
  line-height: 20px;
  background-color: #fff;
  color: #222;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 2px #eee;
}

.web-mnu-btn:hover {
  background-color: #46a8e0;
  color: #fff;
}

.web-btn-01 {
  margin: 5px;
  padding: 5px;
  width: 200px;
  height: 60px;
  line-height: 20px;
  background-color: #fff;
  color: #222;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.web-btn-01:hover {
  background-color: #080;
  color: #fff;
}

.web-box-01 {
  margin: 0px;
  padding: 0px;
  width: 200px;
  height: 60px;
  line-height: 20px;
  background-color: #fff;
  color: #222;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
}

.web-box-01:hover {
  background-color: #080;
  color: #fff;
}

.wnd-close-btn {
  margin: 0px;
  padding: 0px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  font-size:22px;
  cursor: pointer;
}

.wnd-close-btn:hover {
  color: #d7851f;
}

.nota-btn {
  margin: 0px;
  padding: 0px;
  width: 200px;
  height: 60px;
  line-height: 20px;
  background-color: #fff;
  color: #23469f;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  font-size:16px;
}

.nota-btn:hover {
  background-color: #23469f;
  color: #fff;
}
.nota-fkn-btn {
  margin: 0px;
  padding: 0px;
  width: 200px;
  height: 60px;
  line-height: 20px;
  background-color: #fff;
  color: #8a1e1e;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  font-size:16px;
}

.nota-fkn-btn:hover {
  background-color: #8a1e1e;
  color: #fff;
}
.nota-pay-btn {
  margin: 0px;
  padding: 0px;
  width: 200px;
  height: 60px;
  line-height: 20px;
  background-color: #fff;
  color: #366b2d;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  font-size:16px;
}

.nota-pay-btn:hover {
  background-color: #366b2d;
  color: #fff;
}

.nota-grp-btn {
  margin: 0px;
  padding: 0px;
  width: 200px;
  height: 60px;
  line-height: 20px;
  background-color: #fff;
  color: #2571a5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  font-size:16px;
}

.nota-grp-btn:hover {
  background-color: #2571a5;
  color: #fff;
}

.nota-sell-btn {
  margin: 0px;
  padding: 0px;
  min-width: 125px;
  height: 60px;
  line-height: 18px;
  background-color: #fff;
  color: #2571a5;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  font-size:14px;
}

.nota-sell-btn:hover {
  background-color: #2571a5;
  color: #fff;
}

.nota-bet-btn {
  margin: 0px;
  padding: 0px;
  height: 60px;
  line-height: 20px;
  background-color: #fff;
  color: #a52538;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  font-size:16px;
}

.nota-bet-btn:hover {
  background-color: #a52538;
  color: #fff;
}

.web-content {
  margin: 0px;
  padding: 75px 50px;
}
.web-section {
  padding: 5px;
  background-color: #fff;
  color: #000;
}
.web-container {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.web-ico {
  height: 75px;
}
.web-ico:hover {
  fill: #ff9900;
}
.web-icon {
}
.web-icon:hover {
  color: #ff9900;
}
.hello-icon {
  font-size: 32px;
  color: #999;
}
.hello-icon:hover {
  color: #ff9900;
}
.bxm-icon {
  font-size: 32px;
  color: #fff;
}
.bxm-icon:hover {
  color: #ff9900;
}
.ico-22 {
  font-size: 22px;
  color: #999;
}
.ico-22:hover {
  color: #ff9900;
}
.ico-18 {
  font-size: 18px;
  color: #999;
}
.ico-18:hover {
  color: #ff9900;
}
.web-link {
  color: #228ad9;
  cursor: pointer;
}
.web-link:hover {
  color: #ff9900;
}
.web-link-btn {
  padding: 0px 3px;
  height: 50px;
  line-height: 50px;
  color: #228ad9;
  cursor: pointer;
}
.web-link-btn:hover {
  color: #ff9900;
}

.web-emv {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 20px 15px;
  border: 1px solid #ccc;
}

.web-pg {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 0px;
  width: 1024px;
}
.web-bx {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 20px 15px;
  border-bottom: 1px solid #ddd;
}
.web-bx:hover {
  background-color: #f9f9fc;
  color: #276fcc;
  cursor: pointer;
}
.web-bxi {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 20px 15px;
  border-bottom: 1px solid #ddd;
}
.web-bxi:hover {
  background-color: #f9f9fc;
  color: #276fcc;
  cursor: pointer;
}
.web-bxm-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.web-bxm {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 20px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.web-bxm:hover {
  color: #f9f9fc;
  background-color: #ff9900;
  cursor: pointer;
}
.web-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  margin: 10px;
  padding: 10px 15px;
  border: 0px solid #ccc;
}
.web-box_01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  margin: 10px;
  padding: 10px 15px;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.web-box-title {
  margin: 0px;
  width: 100%;
  padding: 10px 15px;
  text-align: center;
  font-size: 22px;
  font-weight: normal;
  border-bottom: 1px solid #ff9900;
}
.web-box-body {
  margin: 0px;
  padding: 10px 15px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  border: 0px solid #ccc;
}
.web-box-foot {
  margin: 0px;
  padding: 10px 15px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  border: 0px solid #cccccc;
}

.web-s0 {
  background-color: rgb(255, 255, 255);
  color: #000;
  display: flex;
  margin: 0px;
  padding: 50px 0px;
}

.web-s1 {
  background-color: rgb(255, 255, 255);
  color: #000000;
  display: flex;
  margin: 0px 5px;
  padding: 5px 15px;
}

.web-s2 {
  background-color: rgb(255, 255, 255);
  color: #000;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.txt-icon {
  min-width: 40px;
}

.btn-tab {
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 100px;
  height: 35px;
  min-height: 35px;
  background: transparent;
  line-height: 35px;
  text-align: center;
}
.btn-tab:hover {
  background-color: #ff9900;
  color: #fff;
}
.btn {
  border: 0px solid #ccc;
  border-radius: 5px;
  min-width: 100px;
  height: 35px;
  min-height: 35px;
  background: transparent;
}
.btn-style {
  background: #008;
}
.btn-icon {
  min-width: 40px;
}
.btn-rp-line {
  width: 100px;
  height: 35px;
  font-size: 28px;
}
.btn-sm-line {
  width: 100px;
  height: 15px;
  font-size: 28px;
}
.dt-btn {
  margin: 0px 5px;
  padding: 0px;
  width: 50px;
  height: 35px;
  line-height: 35px;
  font-size: 25px;
  border-radius: 3px;
  background: #ffffff;
  color: #008800;
  border: 1px solid #008800;
  text-align: center;
}
.dt-btn:hover {
  background-color: #008800;
  color: #fff;
}

.dt-btn-01 {
  margin: 0px 5px;
  padding: 0px 5px;
  width: 50px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  background: #ffffff;
  color: #008800;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
}
.dt-btn-01:hover {
  background-color: #008800;
  color: #fff;
}
.input-rp-01 {
  margin: 0px 5px;
  padding: 0px 5px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.input-rp-01:hover {
  background-color: #008800;
  color: #fff;
}
.btn-rp {
  margin: 5px;
  padding: 5px;
  width: 200px;
  height: 90px;
  line-height: 20px;
  background-color: #fff;
  color: #333;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.btn-rp2 {
  margin: 5px;
  padding: 5px;
  width: 200px;
  height: 75px;
  line-height: 20px;
  background-color: #fff;
  color: #333;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.btn-rp2 i {
  color: #080;
}

.btn-rp2:hover i {
  color: #fff;
}
.btn-rp2:hover {
  background-color: #7ea97e;
  color: #fff;
}

.btn-rp i {
  color: #080;
}

.btn-rp:hover i {
  color: #fff;
}
.btn-rp:hover {
  background-color: #7ea97e;
  color: #fff;
}

.rv-ov-box {
  margin: 5px 5px;
  padding: 0px 5px;
  border: 1px solid #555;
  border-radius: 5px;
}

.rv-ov-btn {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.rv-ov-btn:hover {
  /*background-color: #7ea97e;
  border-radius: 5px;
  */
  color: #ff9900;
}

.rv-lbl {
  width: 100px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: #555;
}
.rv-txt {
  height: 20px;
  line-height: 20px;
  font-size: 18px;
  text-align: left;
  color: #333;
}
.rv-box {
  margin: 5px 5px;
  padding: 0px;
  border: 1px solid #555;
  border-top: 0px solid #555;
  border-radius: 2px;
}
.rv-tab {
  margin: 0px;
  padding: 0px 5px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.rv-tab-btn {
  margin: 0px;
  padding: 0px 5px;
  height: 40px;
  line-height: 40px;
  background-color: #ccc;
  color: #555;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #555;
  border-left: 0px solid #555;
  border-right: 1px solid #555;
  border-radius: 5px 5px 0px 0px;
}
.rv-tab-btn:hover {
  background-color: #7ea97e;
  color: #fff;
}

.rv-top-btn {
  margin: 0px;
  padding: 0px 5px;
  width: 200px;
  height: 40px;
  line-height: 40px;
  background-color: #ccc;
  color: #555;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #555;
  border-left: 0px solid #555;
  border-right: 1px solid #555;
  border-radius: 5px 5px 0px 0px;
}
.rv-top-btn:hover {
  background-color: #7ea97e;
  color: #fff;
}

.white-icon-close {
  color: #fff;
  cursor: pointer;
}
.white-icon-close:hover {
  color: #ccc;
}
.black-icon-close {
  color: #000;
  cursor: pointer;
}
.black-icon-close:hover {
  color: #ccc;
}

.xbtn-hw-blue {
  border: 1px solid #008;
}
.xbtn-hw-blue:hover {
  background-color: #008;
  color: #fff;
}
.btn-hw-large {
  border: 1px solid #fff;
  height: 45px;
  min-width: 200px;
}
.btn-hw-large:hover {
  background-color: #555;
  color: #fff;
  transition: 0.5s;
}

.btn-hw-green {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #008800;
  border: 1px solid #008800;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-green:hover {
  background: #008800;
  color: #ffffff;
  border: 1px solid #008800;
  box-sizing: border-box;
}
.btn-hw-blue {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #000088;
  border: 1px solid #000088;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-blue:hover {
  background: #000088;
  color: #ffffff;
  border: 1px solid #000088;
  box-sizing: border-box;
}
.btn-hw-red {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #880000;
  border: 1px solid #880000;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-red:hover {
  background: #880000;
  color: #ffffff;
  border: 1px solid #880000;
  box-sizing: border-box;
}
.btn-hw-orange {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #ff9900;
  border: 1px solid #ff9900;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-orange:hover {
  background: #ff9900;
  color: #ffffff;
  border: 1px solid #ff9900;
  box-sizing: border-box;
}

.btn-hh-red {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #880000;
  color: #ffffff;
  border: 1px solid #880000;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hh-red:hover {
  background: #ffffff;
  color: #880000;
  border: 1px solid #880000;
  box-sizing: border-box;
}
.btn-hh-green {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #008800;
  color: #ffffff;
  border: 1px solid #008800;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hh-green:hover {
  background: #ffffff;
  color: #008800;
  border: 1px solid #008800;
  box-sizing: border-box;
}

.btn:focus {
  outline: none;
}
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.hide {
    display: none;
}

.w20, .form-control.w20 {
    width: 20px;
}
.w40, .form-control.w40 {
    width: 40px;
}
.w50, .form-control.w50 {
    width: 50px;
}
.w60, .form-control.w60 {
    width: 60px;
}
.w75, .form-control.w75 {
  width: 75px;
}
.w100, .form-control.w100 {
  width: 100px;
}
.w125, .form-control.w125 {
  width: 125px;
}
.w150, .form-control.w150 {
    width: 150px;
}
.w175, .form-control.w175 {
    width: 175px;
}
.w200, .form-control.w200 {
    width: 200px;
}
.w250, .form-control.w250 {
    width: 250px;
}
.w300, .form-control.w300 {
    width: 300px;
}
.w350, .form-control.w350 {
    width: 350px;
}
.w400, .form-control.w400 {
    width: 400px;
}
.w450, .form-control.w450 {
    width: 450px;
}
.w500, .form-control.w500 {
    width: 500px;
}

.h35 {
    height: 35px;
}
.h50 {
    height: 50px;
}
.h80 {
    height: 80px;
}
.h100 {
    height: 100px;
}
.h125 {
    height: 125px;
}
.h150 {
    height: 150px;
}
.h175 {
    height: 175px;
}
.h200 {
    height: 200px;
}
.h250 {
    height: 250px;
}

.selected {
  background: #00a900;
  color:#ffffff;
}
.selected tr, .selected td {
  background: #00a900;
  color:#ffffff;
}
.table-hover tbody tr:hover td {
  background: #ff9900;
  color: #ffffff;
}
.table-hover tbody tr.selected:hover td {
  background: #a90000;
  color: #ffffff;
}

.wf2 {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.mnu {
  z-index: 5999;
}

button:focus {
  outline: none;
}
.dt_input {
  height: 35px;
  padding: 0px 5px;
  text-align: center;
  font-size: 22px;
}
body .dt_input {
  padding: 4px 5px;
  text-align: center;
  font-size: 22px;
}
.dp_input label {
  width: 250px;
  padding: 4px 5px;
  text-align: center;
  font-size: 16px;
}

.divider {
  padding: 5px;
}
.sel_input {
  width: 250px;
  height: 35px;
  padding: 0px 5px;
  text-align: center;
  font-size: 16px;
}
.label_h01 {
  font-size: 42px;
  font-weight: bold;
}
.label_h02 {
  font-size: 30px;
  font-weight: bold;
}
.label_h03 {
  font-size: 20px;
  font-weight: bold;
}
.label_t12 {
  font-size: 12px;
}
.lh_35 {
  line-height: 35px;
}
.rlink_12 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 3px;
  font-size: 12px;
  color: #d44753;
  cursor:pointer;
}
.olink {
  padding: 0px 3px;
  color: #ff9900;
  cursor:pointer;
}
.olink:hover {
  color: #ffee4d;
}
.link_12 {
  padding: 0px 3px;
  font-size: 12px;
  color: #4770d4;
  cursor:pointer;
}
.link_14 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 4px;
  font-size: 14px;
  color: #4770d4;
  cursor:pointer;
}
.link_14:hover {
  background: #4770d4;
  color: #fff;
  border-radius: 5px;
}
.link_16 {
  padding: 0px 4px;
  font-size: 16px;
  color: #4770d4;
  cursor:pointer;
}

.txt_8 {
  font-size: 8px;
}
.txt_10 {
  font-size: 10px;
}
.txt_11 {
  font-size: 11px;
}
.txt_12 {
  font-size: 12px;
}
.txt_14 {
  font-size: 14px;
}
.txt_16 {
  font-size: 16px;
}
.txt_18 {
  font-size: 18px;
}
.txt_20 {
  font-size: 20px;
}
.txt_22 {
  font-size: 22px;
  font-weight: normal;
}
.txt_24 {
  font-size: 22px;
}
.txt_32 {
  font-size: 32px;
}
.txt_42 {
  font-size: 42px;
}
.txt_b16 {
  font-size: 16px;
  font-weight: bold;
}
.txt_b18 {
  font-size: 18px;
  font-weight: bold;
}
.txt_tb_anm {
  font-style: italic;
  font-weight: normal;
}

span.txt_18 {
  font-size: 18px;
}
span.txt_22 {
  font-size: 22px;
}
span.txt_24 {
  font-size: 24px;
}
span.txt_32 {
  font-size: 32px;
}

.txt_left {
  margin: 0px;
  padding: 0px 5px;
  text-align: left;
}
.txt_right {
  margin: 0px;
  padding: 0px 5px;
  text-align: right;
}
.txt_center {
  text-align: center;
}
.txt_box {
  margin: 5px;
  padding: 5px;
  min-width: 225px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 2px #eee;
  font-size: 20px;
  color: #222;
}
.txt_lbl {
  margin: 0px;
  padding: 0px 0px;
  width: 75px;
  font-size: 12px;
  line-height: 1.0;
  text-align: left;
  color: #666;
}
.txt_data {
  margin: 0px;
  padding: 0px 0px;
  font-size: 18px;
  text-align: left;
}
.txt_price {
  margin: 0px;
  padding: 0px 0px;
  font-size: 18px;
  color: #800;
  text-align: right;
}
.txt_btn_link {
  margin: 0px;
  padding: 0px 5px;
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  color: #008;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.txt_btn_link:hover {
  background: #ccc;
  color: #008;
}
.txt_bg_01 {
  background: #F5F7FA;
}
.txt_bg_02 {
  background: #edf4fb;
}

.web-img {
  width: 400px;
  height: 400px;
  object-fit: cover;
}
.web01-img {
  width: 800px;
  height: 400px;
  object-fit: cover;
}
.logo-img {
  width: 100%;
  max-width: 400px;
  object-fit: contain;
}
.web-logo {
  height: 100%;
}
.web02-img {
  max-height: 50px;
  object-fit: cover;
}

.cdibread {
  display: flex;
  flex-direction: row;
  background: #fff;
  margin: 0px;
  margin-bottom: 0px;
  padding: 3px 5px;
  font-size: 14px;
  list-style: none;
}
.cdibread a {
  cursor: pointer;
}
.cdibread li {
  padding: 0px 5px;
}
.cdinav {
  margin: 0px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ff9900;
}

.parallax {
  /* The image used */
  background-image: url("../img/pax00.jpg");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.web-head_1 {
  border-bottom: 1px solid #ccc;
  flex: 0;
  -webkit-flex: 0;
  background-color: transparent;
  background-color: #fff;
  color: grey;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1010;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
  transition: 0.5s;
}
.web-top-txt {
  line-height: 50px;
}
.web-header {
  border-bottom: 1px solid #ccc;
  flex: 0;
  -webkit-flex: 0;
  background-color: #1f4485;
  color: #e5e5e5;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1015;
}
.web-top-sub {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  padding: 0px 5px;
  background-color: #0946b6;
  background-color: #dfe0e2;
  color: #222;
  border-bottom: 1px solid #ccc;
  z-index: 1010;
}

.web-top {
  top: 0;
  width: 100%;
  height: 50px;
  padding: 0px 5px;
  background-color: #2a5bb6;
  background-color: #dfe0e2;
  color: #222;
  border-bottom: 1px solid #ccc;
  z-index: 1020;
}

.web-top-sys {
  position: fixed;
  top: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0px 5px;
  background-color: #2a5bb6;
  background-color: #dfe0e2;
  color: #222;
  border-bottom: 1px solid #ccc;
  z-index: 1020;
}
.web-xtop .top-txt {
  line-height: 50px;
}
.web-xbody {
  background: #fff;
  width: 100%;
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px 5px 0px 5px;
  overflow: auto;
}
.web-top-sep {
  margin: 10px 5px;
  padding: 0px;
  border-right: 1px solid #888;
}
.web-sys-sep {
  margin: 5px 5px;
  padding: 0px;
  border-right: 1px solid #bbb;
}

.web-top-usr {
  padding: 5px 2px;
  font-size: 14px;
  line-height: 20px;
  border-right: 0px solid #ccc;
  text-align: left;
}
.web-top-usr:hover {
  color: #B388FF;
  cursor: pointer;
}

.web-x-icon {
  padding: 0px 2px;
  font-size: 20px;
}
.web-x-icon:hover {
  color: #B388FF;
  cursor: pointer;
}

.web-top-icon {
  font-size: 20px;
  line-height: 50px;
}
.web-top-icon:hover {
  color: #B388FF;
  cursor: pointer;
}
.web-fkn-icon {
  font-size: 20px;
  line-height: 35px;
}
.web-fkn-icon:hover {
  color: #B388FF;
  cursor: pointer;
}

.rv-top-icon {
  font-size: 20px;
  line-height: 35px;
}
.rv-top-icon:hover {
  color: #B388FF;
  cursor: pointer;
}
.web-top-content {
}
.web-head-content {
}

.web-foot-icon {
  font-size: 18px;
  line-height: 30px;
}
.web-foot-icon:hover {
  color: #ff9900;
  cursor: pointer;
}

.web-foot-fkn {
  padding: 5px 2px;
  font-size: 14px;
  line-height: 20px;
  border-right: 0px solid #ccc;
  text-align: left;
}
.web-foot-fkn:hover {
  color: #B388FF;
  cursor: pointer;
}

.web-login {
}
.web-login input {
  font-size: 18px;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.web-login label {
  font-size: 12px;
  text-align: left;
}

.web-b01 {
  margin: 4px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(231, 108, 108, 0.14), 0 5px 24px 4px rgba(0,0,0,.12);
}

.web-bas {
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px 3px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  -webkit-flex: 1;
}
.web-sub {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  -webkit-flex: 1;
}

.web-rp-box {
  margin: 4px;
  padding: 5px 10px;
  min-height: 75px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.web-rp-box:hover {
  background: #ccc;
}
.web-wf-2 {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.divider_orange {
  border-top: 1px solid #f99000;
}
.divider_grey {
  border-top: 1px solid #d5d5d5;
}
.divider_blue {
	border-top: 1px solid #000088;
}
.divider_green {
	margin: 0px;
	padding: 0px;
	border-top: 1px solid #008800;
}
.horz_red {
	margin: 0px;
	padding: 0px;
	border-left: 1px solid #880000;
}
.vert_orange {
	margin: 0px;
	padding: 0px;
	border-left: 1px solid #f99000;
}
.tab_row {
    display: flex;
    flex-direction: row;
	margin: 0px;
	padding: 0px;
	border-bottom: 1px solid #ccc;
}
.tab_header {
	margin: 0px;
	padding: 5px 5px;
    max-width: 200px;
    text-align: center;
    border-right: 1px solid #c5c5c5;
	border-bottom: 0px solid #008800;
    cursor: pointer;
}
.tab_content {
    padding: 0px 5px;
    min-height: 50px;
     overflow: auto;
}
.cdi-label {
	margin: 0px;
	padding: 5px 0px;
	width: 100px;
    color: #222;
    text-align: left;
    vertical-align: text-bottom;
    font-size: 12px;
    font-weight: normal;
}
.cdi-txt {
  margin: 0px;
  padding: 5px 0px;
  color: #222;
  text-align: left;
  vertical-align: text-bottom;
  font-size: 16px;
  font-weight: normal;
}

.web-txt-32 {
    text-align: left;
    font-size: 32px;
    font-weight: normal;
}
.web-bg00 {
  background-image: url("../img/kassabg.jpg");
}
.bg-border-00 {
  border: 1px solid #ccc;
}
.bg-w00 {
  color: #fff;
  background: #005C97;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #363795, #005C97);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #363795, #005C97); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-w01 {
  color: #fff;
  background: #4d89e2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3a7bd5, #4d89e2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3a7bd5, #4d89e2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-w05 {
  color: #fff;
  background: #ff9900;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffaa00, #ff5500);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffaa00, #ff5500); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-w05 {
  color: #fff;
  background: #ff9900;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffaa00, #ff5500);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffaa00, #ff5500); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.elementToFadeInAndOut {
    animation: fadeinout 4s linear 1 forwards;
}

@keyframes fadeinout {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.web-start {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.web-cc {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.web-end {
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}
.web-head-row {
  display: flex;
  flex-direction: row;
  padding: 5px 5px;
}
.web-kvitto {
  margin: 0px auto;
  padding: 15px 10px;
  width: 450px;
  min-width: 450px;
  border: 1px solid #ccc;
}

.ham-mnu {
  position: relative;
  display: none;
  flex: 0;
  -webkit-flex: 0;
  width: 100%;
  font-size: 18px;
  font-weight: normal;
}
.ham-mnu .item {
  margin: 4px;
  padding: 5px 10px;
  height: 35px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.web-hide {
    display: none;
}

@media screen and (min-width: 800px) {
  #mx1 {
    background-color: #800;
    color: #fff;
  }
}
@media screen and (max-width: 800px) {
  #mx1 {
    background-color: #008;
    color: #fff;
  }
  .web-top-mnu {
    display: none;
  }
  .web-row {
    flex-direction: column;
  }
  .web-logo {
    max-width: 30%;
  }
  .web-mnu-bars {
    display: flex;
  }
  .web-center {
    padding: 0px 15px;
  }
  .web-start {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .web-cc {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .web-end {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}


.cdi-hmx {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  width: 400px;;
  font-size: 18px;
  font-weight: normal;
  z-index: 99;
  border: 1px solid #ccc;
}
.cdi-hmx-header {
  margin: 0px;
  padding: 5px 10px;
  height: 45px;
  cursor: pointer;
}
.cdi-hmx-body {
  flex: 1;
  -webkit-flex: 1;
  margin: 0px;
  padding: 5px 10px;
  height: 45px;
  cursor: pointer;
}
.cdi-hmx-item {
  border-bottom: 1px solid #ccc;
}
.cdi-hmx-footer {
  background-color: #800;
  color: #fff;
  padding: 5px 10px;
  height: 45px;
  cursor: pointer;
}
.odd {
  background-color: #ded;
}
.card_cdi {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, .125);
}
.nav_active {
  color: #2296e3;
  cursor: pointer;
}
.bg-stripes {
  background: repeating-linear-gradient(
    -55deg,
    #222,
    #222 10px,
    #333 10px,
    #333 20px
  );
}

.cal_head {
  height: 45px;
  line-height: 45px;
  background-color: #7da0e0;
  border: 1px solid rgba(0, 0, 0, .125);
  text-align: center;
}
.cal_row {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
}
.cal_row:hover {
  background-color: #b4c4e3;
}

.twocolumns {
  padding:10px;
  width:100%;
  height:100%;
  -moz-column-count: 2;
  -moz-column-gap: 45px;
  -webkit-column-count: 2;
  -webkit-column-gap: 45px;
  column-count: 2;
  column-gap: 45px;
  column-fill: auto;
}
.threecolumns {
  padding:10px;
  width:100%;
  height:100%;
  -moz-column-count: 3;
  -moz-column-gap: 55px;
  -webkit-column-count: 3;
  -webkit-column-gap: 55px;
  column-count: 3;
  column-gap: 55px;
  column-fill: auto;
}
.cdi-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
}
.cdi-grid-row {
  padding:5px;
  height:80px;
}
.cdi-grid-row:hover {
  background-color: #7da0e0;
  background-color: #4781ec;
  color: #fff;
}

.cdi_val_box {
  margin: 3px 0px;
  padding: 3px 8px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.cdi_val_box label {
  padding: 3px 20px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #555;
}
.cdi_val_box input {
padding: 3px 20px;
font-size: 18px;
font-weight: normal;
font-style: normal;
border: 0px solid #000;
color: #000;
}
.cdi_val_box input:focus{
  outline: none;
}

@media screen and (max-width: 800px) {
  .web-top-usr {
    display: none;
  }
  .web-foot-btn {
    display: none;
  }
  .web-side {
    display: block;
    position: absolute;
  }
  }
