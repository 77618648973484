.tb-lt01 tbody td {
  marging: 0px;
  padding: 0px;
}

.tb {
  marging: 0px;
  padding: 2px 5px;
  font-size: 14px;
}
.tb th {
  padding: 4px 5px;
  background: #fff;
  font-weight: normal;
}
.tb td {
  padding: 8px 5px;
  overflow: hidden;
}
table.table th, table.table td {
    padding-top: 5px;
    padding-bottom: 5px;
}
table th {
    font-size: 14px;
    font-weight: 300;
}
table td {
    font-size: 14px;
    font-weight: 400;
}

.tb .selected {
  background: #080;
  color: #fff;
}
.tb-cdi {
  height:200px;
  overflow-y: auto;
}

.fix_head tbody{
  display:block;
  table-layout: fixed;
  overflow:auto;
  height:100%;
  width:100%;
}
.fix_head thead tr{
  display:table;
  table-layout: fixed;
  width:100%;
  border-bottom: 1px solid #ff9900;
}
.fix_head tbody tr{
  display:table;
  table-layout: fixed;
  width:100%;
}


.fixed_header tbody{
  display:block;
  table-layout: fixed;
  overflow:auto;
  height:100%;
  width:100%;
}
.fixed_header thead tr{
  display:table;
  table-layout: fixed;
  width:100%;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.fixed_header tbody tr{
  display:table;
  table-layout: fixed;
  width:100%;
}

table.tbx {
  display: grid;
  min-width: 100%;
  width: auto;
  flex: 1;
  border: 1px solid #ccc;
  border-collapse: collapse;
  grid-template-columns:
    minmax(150px, 1fr)
    minmax(150px, 3.33fr)
    minmax(150px, 3.33fr)
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr);
}

.tbx thead
{
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0, 0, 0, 0), 0 1px 10px 0 rgba(0,0,0,.12);
}
.tbx thead,
.tbx tbody,
.tbx tr {
  display: contents;
}

.tbx th,
.tbx td {
  border: 1px solid #ccc;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tbx th {
  position: sticky;
  top: 0;
  background: #fff;
  text-align: left;
  font-weight: normal;
  font-size: 1.1rem;
  color: #222;
  height: 35px;
}

.tbx th:last-child {
  border: 0;
}

.tbx td {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #808080;
}
.tbx .selected {
  background: #080;
  color: #fff;
}
.tbx tr:hover td {
  background: #ff9900;
  color: #fff;
}
.tbx tr:nth-child(even):hover td {
  background: #ff9900;
  color: #fff;
}

.tbx tr:nth-child(even) td {
  background: #f8f6ff;
}

.tb_row {
  cursor: pointer;
}
.tb_row:hover {
  background: #ff9900;
  color: #fff;
}
.tbs01 {
}
.tbs01 tbody {
  border-bottom: 2px solid #d0924c; /*  #cc8533; #ea973b; */
}
.tbs02 {
}
.tbs02 tr {
/*  border-bottom: 2px solid #cc8533; */
  border-bottom: 2px solid #d0924c;
}
.tbs02 td {
  border-bottom: 2px solid #d0924c;
}
