.tid_ctrl {
  padding: 2px 5px;
  background: #fff;
  height: 35px;
  line-height: 15px;
  font-size: 14px;
  border-left: 1px solid #eee;
}
.tid_ctrl span {
  padding: 0px;
  background: #fff;
}
.tid_ctrl:hover {
  color: #ff9900;
  cursor: pointer;
}
